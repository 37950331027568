import '@js/behaviors/tooltip.jquery'
import $ from 'jquery'
import { hideAll } from 'tippy.js'

$.fn.taxAccountingTooltip = function (options) {
  const defaultOptions = {
    delay: 0,
    placement: 'top-end',
  }
  const tippySettings = $.extend(defaultOptions, options)

  this.tooltip(
    $.extend(tippySettings, {
      content: (reference) => $(reference).find('.hidden').text(),
      hideOnClick: false,
      interactive: true,
      onShown(instance) {
        $('body').on('keydown.tooltip', (event) => {
          const escKey = 27
          if (event.which === escKey) {
            instance.hide()
          }
        })

        // Fix for IE11 loosing focus when the tooltip is shown
        const $reference = $(instance.reference)
        if ($reference.not(':input')) {
          $reference.find(':input:not([disabled])').trigger('focus')
        }
      },
      trigger: 'click',
    })
  )

  this.on('focusin', function () {
    this._tippy.show()
    hideAll({ exclude: this._tippy })
  })

  this.on('focusout', function (event) {
    this._tippy.hideWithInteractivity(event)
  })

  return this
}
