import axios from 'axios'
import type { HydraCollectionResponse } from '@js/types'
import type { Status } from '@js/model/status'

export const basePath = '/api/statuses'

function fetchAll() {
  return axios.get<HydraCollectionResponse<Status>>(basePath, {
    params: { pagination: false },
  })
}

function fetchById(id: number) {
  return axios.get<Status>(`${basePath}/${id}`)
}

function deleteById(id: number) {
  return axios.delete<Status>(`${basePath}/${id}`)
}

export const statusApi = {
  fetchAll,
  fetchById,
  fetchByIri: function (iri: NonNullable<Status['@id']>) {
    return axios.get<Status>(iri)
  },
  delete: deleteById,
}
