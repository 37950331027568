import { createQueryKeys } from '@lukemorales/query-key-factory'
import { unref } from 'vue'
import { workflowApi } from '@js/api/workflowApi'
import type { MaybeRef } from '@vueuse/core'
import type { Workflow } from '@js/model/workflow'

export const workflows = createQueryKeys('workflows', {
  single: (id: MaybeRef<Workflow['id']>) => {
    return {
      queryKey: [id],
      queryFn: () => workflowApi.fetchById(unref(id)).then((response) => response.data),
    }
  },
})
