import tippy, { roundArrow } from 'tippy.js'
import $ from 'jquery'

const $body = $('body')

$.fn.tooltip = function (options) {
  const defaultOptions = {
    allowHTML: true,
    content: (reference) => {
      const title = reference.getAttribute('title')
      reference.removeAttribute('title')
      return title
    },
    animation: 'fade',
    arrow: roundArrow,
    delay: [450, 0],
    duration: [150, 150],
    interactive: true,
    interactiveBorder: 0,
    onShow(instance) {
      const title = instance.reference.getAttribute('title')
      if (title) {
        instance.setContent(title)
        instance.reference.removeAttribute('title')
      }
    },
    onHidden() {
      $body.off('.tooltip')
    },
    onShown(instance) {
      $body.on('keydown.tooltip', (event) => {
        const escKey = 27
        if (event.which === escKey) {
          instance.hide()
        }
      })
    },
    placement: 'auto',
    trigger: 'mouseenter focus',
  }

  this.each((i, element) => {
    tippy(element, {
      ...defaultOptions,
      placement: element.dataset.tooltipPosition || 'auto',
      ...options,
    })
  })

  return this
}
