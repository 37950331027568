import { createQueryKeys } from '@lukemorales/query-key-factory'
import { unref } from 'vue'
import { unitHierarchyApi } from '@js/api/unitHierarchyApi'
import type { MaybeRef } from '@vueuse/core'
import type { Unit } from '@js/model/unit'

export const unitHierarchies = createQueryKeys('unitHierarchies', {
  all: {
    queryKey: null,
    queryFn: () => unitHierarchyApi.fetchAll().then((response) => response.data),
  },
  list: (params) => ({
    queryKey: [params],
    queryFn: () => unitHierarchyApi.fetchByQuery(unref(params)).then((response) => response.data),
  }),
  single: (id: MaybeRef<Unit['id']>) => {
    return {
      queryKey: [id],
      queryFn: () => unitHierarchyApi.fetchById(unref(id)).then((response) => response.data),
      contextQueries: {
        structure: (date: MaybeRef<string>) => ({
          queryKey: [date],
          queryFn: () =>
            unitHierarchyApi
              .fetchStructure(unref(id), unref(date))
              .then((response) => response.data),
        }),
      },
    }
  },
})
