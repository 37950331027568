import axios from 'axios'
import flushPromises from 'flush-promises'
import { jwtDecode } from 'jwt-decode'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { authApi } from '@js/api/authApi'
import { userApi } from '@js/api/userApi'
import queryClient from '@js/queryClient'
import Cookie from '@js/utilities/cookie'
import type { Feature } from '@js/stores/feature'
import type { AuthorizationString } from '@js/model/authorization'
import type { Role } from '@js/model/role'
import type { User } from '@js/model/user'

export type JWT = {
  id: number
  exp: number
  roles: Array<Role>
  features: Array<Feature>
}

export const useAuthStore = defineStore('auth', () => {
  const user = ref<User | undefined>()
  const token = ref<string | undefined>()

  const jwt = computed(() => {
    return token.value ? jwtDecode<JWT>(token.value) : undefined
  })

  const rolesAndAuthorizations = computed(() => {
    if (!user.value) {
      return []
    }
    return [...(user.value.roles ?? []), ...(user.value.authorizations ?? [])]
  })

  function isTokenValid() {
    return new Date() < expires.value
  }

  function tokenNeedsRefresh() {
    // refresh the token 5 seconds before it expires
    return new Date().getTime() > expires.value.getTime() - 5000
  }

  const expires = computed(() => {
    return jwt.value ? new Date(jwt.value.exp * 1000) : new Date(0)
  })

  const isRefreshing = ref<boolean>(false)
  async function refreshToken() {
    if (isRefreshing.value) {
      // The JWT token is already being refreshed.
      while (isRefreshing.value) {
        await flushPromises()
      }
      return
    }
    isRefreshing.value = true

    try {
      await authApi.refreshJwt()
      await processJwtCookie()
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return
      }
      throw error
    } finally {
      isRefreshing.value = false
    }
  }

  async function processJwtCookie() {
    token.value = Cookie.read('JWT') ?? undefined

    if (jwt.value) {
      const { data } = await userApi.fetchById(jwt.value.id)
      user.value = data
    }
  }

  const hasRole = computed(() => {
    return (role: Role) => user.value?.roles?.includes(role) ?? false
  })

  const hasAuthorization = computed(() => {
    return (authorization: AuthorizationString) =>
      user.value?.authorizations?.includes(authorization) ?? false
  })

  const hasRoleOrAuthorization = computed(() => {
    return (roleOrAuth: Role | AuthorizationString) =>
      rolesAndAuthorizations.value.includes(roleOrAuth)
  })

  async function logout() {
    Cookie.erase('JWT')
    Cookie.erase('U2SESSION')
    Cookie.erase('REMEMBERME')

    await authApi.logout()

    user.value = undefined
    token.value = undefined

    // Clear all local data to ensure that the user next logging in won't see data he has no access to
    queryClient.getQueryCache().clear()
  }

  async function ensureToken() {
    if (tokenNeedsRefresh()) {
      await refreshToken()
    }
  }

  return {
    ensureToken,
    expires,
    hasAuthorization,
    hasRole,
    hasRoleOrAuthorization,
    isRefreshing,
    isTokenValid,
    jwt,
    logout,
    processJwtCookie,
    refreshToken,
    token,
    tokenNeedsRefresh,
    user,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot))
}
