import axios from 'axios'
import type { ApiResourceId, HydraCollectionResponse } from '@js/types'
import type { Workflow } from '@js/model/workflow'
import type { SortingDirection } from '@js/utilities/getNextSortingDirection'

const basePath = '/api/workflows'

function fetchAll() {
  return fetchByQuery({ pagination: false })
}

function fetchByBindingId(bindingId: string) {
  return fetchByQuery({ 'bindings.bindingId': bindingId })
}

function fetchById(id: number) {
  return fetchByIri(`${basePath}/${id}`)
}

function fetchByIri(iri: ApiResourceId) {
  return axios.get<Workflow>(iri)
}

function fetchByQuery(query?: {
  page?: number
  itemsPerPage?: number
  pagination?: boolean
  'sort[name]'?: SortingDirection
  'bindings.bindingId'?: string | Array<string>
  search?: string | Array<string>
}) {
  return axios.get<HydraCollectionResponse<Workflow>>(basePath, {
    params: { ...query },
  })
}

export const workflowApi = {
  fetchAll,
  fetchByBindingId,
  fetchById,
  fetchByIri,
  fetchByQuery,
}
