import { computed } from 'vue'
import BaseLayout from '@js/layouts/BaseLayout.vue'
import fetchUser from '@js/assets/router/fetchUser'
import Translator from '@js/translator'
import { useUserStore } from '@js/stores/user'
import type { RouteLocation, RouteRecordRaw } from 'vue-router'

export default [
  {
    component: () => import('@js/pages/user/UserList.vue'),
    meta: {
      auth: 'ROLE_USER_GROUP_ADMIN',
      layout: BaseLayout,
      layoutFormat: 'wide',
      globalSearch: {
        icon: 'users',
        name: () => Translator.trans('u2_core.users'),
      },
    },
    name: 'UserList',
    path: '/user',
  },
  {
    component: () => import('@js/pages/user/UserEdit.vue'),
    meta: {
      layout: BaseLayout,
      title: (params: { id: number }) =>
        computed(() => {
          const userStore = useUserStore()
          return (
            Translator.trans('u2_core.user') + ` ${userStore.getUserById(+params.id)?.username}`
          )
        }),
    },
    name: 'UserEdit',
    path: '/user/:id/edit',
    props: (to: RouteLocation) => ({
      user: to.meta.user,
    }),
    beforeEnter: (to: RouteLocation) => fetchUser(to),
  },
  {
    component: () => import('@js/pages/user/UserNew.vue'),
    meta: {
      auth: 'ROLE_USER_GROUP_ADMIN',
      layout: BaseLayout,
    },
    name: 'UserNew',
    path: '/user/new',
  },
  {
    component: () => import('@js/pages/user/UserSettingsEdit.vue'),
    meta: {
      layout: BaseLayout,
      globalSearch: {
        icon: 'config',
        name: () => Translator.trans('u2_core.edit_user_settings'),
      },
    },
    name: 'UserSettingsEdit',
    path: '/user-setting/edit',
  },
] as Array<RouteRecordRaw>
