import axios from 'axios'
import { basePath as layoutCollectionBasePath } from '@js/api/layoutCollectionApi'
import type { PermissionMask } from '@js/model/permission'
import type { HydraCollectionResponse } from '@js/types'
import type { Task } from '@js/model/task'
import type { LayoutCollection } from '@js/model/layoutCollection'

export const basePath = '/api/tasks'
function fetchLayoutCollections(taskId: Task['id'], permission?: PermissionMask) {
  return axios.get<HydraCollectionResponse<LayoutCollection>>(
    `${basePath}/${taskId}/layout-collections`,
    {
      params: { permission },
    }
  )
}

function addLayoutCollection(taskId: Task['id'], layoutCollectionId: LayoutCollection['id']) {
  return axios.post<HydraCollectionResponse<LayoutCollection>>(
    `${basePath}/${taskId}/layout-collections`,
    {
      layoutCollection: `${layoutCollectionBasePath}/${layoutCollectionId}`,
    }
  )
}

function removeLayoutCollection(taskId: Task['id'], layoutCollectionId: LayoutCollection['id']) {
  return axios.delete<HydraCollectionResponse<LayoutCollection>>(
    `${basePath}/${taskId}/layout-collections/` + layoutCollectionId
  )
}

function fetchTaskById(taskId: Task['id']) {
  return axios.get<Task>(`/api/tasks/${taskId}`)
}

export const taskApi = {
  fetchTaskById,
  fetchLayoutCollections,
  removeLayoutCollection,
  addLayoutCollection,
}
