import type { RouteLocationGeneric } from 'vue-router'

function resolveRouteName(code?: number) {
  switch (code) {
    case 403:
      return 'Error403'
    case 404:
      return 'Error404'
    case 503:
      return 'Error503'
    default:
      return 'Error'
  }
}

export function createErrorLocation(to: RouteLocationGeneric, code?: number) {
  return {
    name: resolveRouteName(code),
    params: {
      pathMatch: to.path.substring(1).split('/'),
    },
    query: to.query,
    hash: to.hash,
  }
}
