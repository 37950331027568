import { createQueryKeys } from '@lukemorales/query-key-factory'
import { userGroupApi } from '@js/api/userGroupApi'

export const userGroups = createQueryKeys('userGroups', {
  all: {
    queryKey: null,
    queryFn: () => userGroupApi.fetchAll().then((response) => response.data),
  },
  single: (id) => {
    return {
      queryKey: [id],
      contextQueries: {
        directUsers: {
          queryKey: null,
          queryFn: () => userGroupApi.fetchAssignedUsers(id).then((response) => response.data),
        },
        roles: {
          queryKey: null,
          queryFn: () => userGroupApi.fetchAssignedRoles(id).then((response) => response.data),
        },
        inheritedRoles: {
          queryKey: null,
          queryFn: () => userGroupApi.fetchInheritedRoles(id).then((response) => response.data),
        },
        units: {
          queryKey: null,
          queryFn: () => userGroupApi.fetchAssignedUnits(id).then((response) => response.data),
        },
      },
    }
  },
})
