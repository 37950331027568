import axios from 'axios'
import type { ActionType } from '@js/model/workflow'
import type { HydraCollectionResponse } from '@js/types'

export const basePath = '/api/workflow/transition/action-types'

function fetchAll() {
  return axios.get<HydraCollectionResponse<ActionType>>(basePath, {
    params: { pagination: false },
  })
}

export const transitionActionTypeApi = {
  fetchAll,
}
