import axios from 'axios'
import type { ApiResourceId } from '@js/types'
import type { Transition } from '@js/model/transition'

const basePath = '/api/transitions'

function fetchById(id: number) {
  return fetchByIri(`${basePath}/${id}`)
}

function fetchByIri(iri: ApiResourceId) {
  return axios.get<Transition>(iri)
}

export const workflowTransitionApi = {
  fetchById,
  fetchByIri,
}
