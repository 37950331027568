import axios from 'axios'
import { computed } from 'vue'
import ConfigurationDataLayout from '@js/layouts/ConfigurationDataLayout.vue'
import Translator from '@js/translator'
import type { RouteLocation, RouteRecordRaw } from 'vue-router'

export default [
  {
    component: () => import('@js/pages/workflow/WorkflowNew.vue'),
    meta: {
      auth: 'ROLE_ADMIN',
      layout: ConfigurationDataLayout,
    },
    name: 'WorkflowNew',
    path: '/workflow/new',
  },

  {
    component: () => import('@js/pages/workflow/WorkflowList.vue'),
    meta: {
      auth: 'ROLE_ADMIN',
      layout: ConfigurationDataLayout,
      globalSearch: {
        icon: 'config',
        name: () => Translator.trans('u2_core.workflows'),
      },
    },
    name: 'WorkflowList',
    path: '/workflow',
  },

  {
    component: () => import('@js/pages/workflow/WorkflowEdit.vue'),
    meta: {
      auth: 'ROLE_ADMIN',
      layout: ConfigurationDataLayout,
      title: (params: { id: number }) => {
        return `${Translator.trans('u2_core.workflow.edit_workflow')} #${params.id}`
      },
    },
    name: 'WorkflowEdit',
    path: '/workflow/:id/edit',
    props: (route: RouteLocation) => ({ id: +route.params.id }),
  },

  {
    component: () => import('@js/pages/workflow/TransitionNew.vue'),
    meta: {
      auth: 'ROLE_ADMIN',
      layout: ConfigurationDataLayout,
    },
    name: 'WorkflowTransitionNew',
    path: '/workflow/:workflowId/transition/new',
    props: (route: RouteLocation) => ({ workflowId: +route.params.workflowId }),
  },

  {
    component: () => import('@js/pages/workflow/TransitionEdit.vue'),
    meta: {
      auth: 'ROLE_ADMIN',
      layout: ConfigurationDataLayout,
      title: (params: { id: number }) => {
        return `${Translator.trans('u2.transition')} #${params.id}`
      },
    },
    name: 'WorkflowTransitionEdit',
    path: '/workflow/transition/:id/edit',
    props: (route: RouteLocation) => ({ id: +route.params.id }),
  },

  {
    component: () => import('@js/pages/workflow/ConditionNew.vue'),
    meta: {
      auth: 'ROLE_ADMIN',
      layout: ConfigurationDataLayout,
      title: () => {
        return Translator.trans('u2_core.workflow.adding_condition_to_transition')
      },
    },
    name: 'WorkflowConditionNew',
    path: '/workflow/transition/:transitionId/condition/add/:conditionTypeId',
    props: (route: RouteLocation) => ({
      transitionId: route.params.transitionId,
      conditionTypeId: route.params.conditionTypeId,
    }),
  },
  {
    component: () => import('@js/pages/workflow/CheckNew.vue'),
    meta: {
      auth: 'ROLE_ADMIN',
      layout: ConfigurationDataLayout,
    },
    name: 'WorkflowCheckNew',
    path: '/workflow/:id/check/new',
    props: (route: RouteLocation) => ({ id: +route.params.id }),
  },

  {
    component: () => import('@js/pages/workflow/CheckEdit.vue'),
    meta: {
      auth: 'ROLE_ADMIN',
      layout: ConfigurationDataLayout,
      title: (params: { id: number }) => {
        return `${Translator.trans('u2_core.workflow.check')} #${params.id}`
      },
    },
    name: 'WorkflowCheckEdit',
    path: '/workflow/:id/check/:checkId/edit',
    props: (route: RouteLocation) => ({ id: +route.params.id, checkId: +route.params.checkId }),
  },
  {
    component: () => import('@js/pages/workflow/WorkflowBindingList.vue'),
    meta: {
      auth: 'ROLE_ADMIN',
      layout: ConfigurationDataLayout,
      globalSearch: {
        icon: 'config',
        name: () => Translator.trans('u2_core.workflow_assignments'),
      },
    },
    name: 'WorkflowBindingList',
    path: '/workflow-assignment',
  },
  {
    component: () => import('@js/pages/workflow/WorkflowBindingEdit.vue'),
    meta: {
      auth: 'ROLE_ADMIN',
      layout: ConfigurationDataLayout,
      title: (params: { workflowBinding: { readableName: string } }) =>
        computed(() => {
          return Translator.trans('u2_core.workflow.edit_workflow_assignment_with_given_name', {
            workflow_name: params.workflowBinding?.readableName || '',
          })
        }),
    },
    name: 'WorkflowBindingEdit',
    path: '/workflow-assignment/:bindingId/edit',
    props: (to: RouteLocation) => ({ bindingId: to.params.bindingId }),
    beforeEnter: async (to: RouteLocation) => {
      to.params.workflowBinding = (
        await axios.get(`/api/workflow-bindings/${to.params.bindingId}`)
      ).data
    },
  },
] as Array<RouteRecordRaw>
