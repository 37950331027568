import $ from 'jquery'
import ScrollTo from '@js/utilities/scroll-to'
import Url from '@js/utilities/url'

/**
 * jQuery plugin to bind scrolling to elements
 *
 * @param options
 * @returns {jQuery}
 */
$.fn.scrollTo = function (options) {
  /**
   * Define plugin default options
   */
  const settings = $.extend(
    {
      scrollSpeed: ScrollTo.defaultScrollSpeed,
    },
    options
  )

  this.on('click.scrollTo', function (event) {
    const $this = $(this)

    if ($this.hasClass('disabled')) {
      // Button/link is disabled, stop execution
      return
    }
    if (event.which !== 1) {
      // Only trap left clicks
      return true
    }

    event.preventDefault()

    const scrollTarget = $this.attr('href')

    // Scroll the page
    ScrollTo.scrollTo(scrollTarget, settings.scrollSpeed)

    // Add the target hash to the url
    Url.updateHash(scrollTarget)
  })

  /**
   * Return the object to allow chaining with other jQuery plugins
   */
  return this
}
