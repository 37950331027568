import { acceptHMRUpdate, defineStore } from 'pinia'
import unionBy from 'lodash/unionBy'
import { userApi } from '@js/api/userApi'
import type { ApiResourceId } from '@js/types'
import type { User } from '@js/model/user'

type State = {
  users: Array<User>
}

export const useUserStore = defineStore('user', {
  state: (): State => ({
    users: [],
  }),
  getters: {
    getUserByIri: (state) => {
      return (userIri: ApiResourceId) => state.users.find((user) => user['@id'] === userIri)
    },
    getUserById: (state) => {
      return (userId: number) => state.users.find((user) => user.id === userId)
    },
  },
  actions: {
    async fetchUser(userIri: ApiResourceId) {
      if (this.getUserByIri(userIri)) {
        return
      }
      const { data } = await userApi.fetchByIri(userIri)
      this.setUser(data)
    },
    setUser(user: User) {
      this.users = unionBy([user], this.users, 'id')
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot))
}
