import axios from 'axios'
import Routing from '@js/Routing'
import type { DocumentSection } from '@js/model/document'
import type { TaskExtra, TaskShortName } from '@js/model/task'
import type { Attachment } from '@js/model/attachment'

export type DocumentEditDataSection = {
  section: DocumentSection
  subSections: Array<DocumentEditDataSection>
}

export type DocumentEditDataResponse = {
  newSectionPath: string
  attachments: Array<Attachment>
  sections: Array<DocumentSection>
  documentName: string
  sectionsHtml: string
  routes: { edit: string }
  userCanEditContent: boolean
  userCanEditConfiguration: boolean
}

function fetchByTask(shortName: TaskShortName, id: TaskExtra['taskTypeId']) {
  return axios.get<DocumentEditDataResponse>(
    Routing.generate('u2_tasktype_editdocument_data', {
      shortName,
      id,
    })
  )
}

function fetchRenderedSections(shortName: TaskShortName, id: TaskExtra['taskTypeId']) {
  return axios.get<Array<{ id: DocumentSection['id']; content: string }>>(
    Routing.generate('u2_tasktype_renderdocumentsections', {
      shortName,
      id,
    })
  )
}

export const documentEditDataApi = {
  fetchByTask,
  fetchRenderedSections,
}
