import { createQueryKeys } from '@lukemorales/query-key-factory'
import { unref } from 'vue'
import { periodApi } from '@js/api/periodApi'
import type { Period } from '@js/model/period'
import type { MaybeRef } from '@vueuse/core'

export const periods = createQueryKeys('periods', {
  all: {
    queryKey: null,
    queryFn: () => periodApi.fetchAll().then((response) => response.data),
  },
  single: (id: MaybeRef<Period['id']>) => {
    return {
      queryKey: [id],
      queryFn: () => periodApi.fetchById(unref(id)).then((response) => response.data),
    }
  },
})
