import { StatusCodes } from 'http-status-codes'
import { useSessionStorage } from '@vueuse/core'
import router from '@js/router'
import { createErrorLocation } from '@js/router/helpers'
import type { HtmlFormResponse, HydraErrorResponse } from '@js/types'
import type { AxiosResponse } from 'axios'

type ErrorResponse = AxiosResponse | HydraErrorResponse | HtmlFormResponse
export default function handleErrorResponse(errorResponse: ErrorResponse): boolean {
  switch (errorResponse.status) {
    case StatusCodes.UNAUTHORIZED:
      return handleUnauthorized(errorResponse)
    case StatusCodes.SERVICE_UNAVAILABLE:
      return handleServiceUnavailable()
    case StatusCodes.MISDIRECTED_REQUEST:
      return handleMisdirectedRequest()
  }
  return false
}

function handleUnauthorized(errorResponse: ErrorResponse): boolean {
  if (errorResponse.data?.['2faComplete'] === false) {
    router.push({ name: 'ConfirmTwoFactor' })
    return true
  }

  const url = errorResponse.request.responseURL
  if (url.includes('/refresh-jwt')) {
    return false
  }
  // We are not refreshing the jwt so go to the logout route to clean up credentials
  router.push({ name: 'AppLogout' })
  return false
}

function handleServiceUnavailable(): boolean {
  const to = router.currentRoute.value
  const redirectUrl = useSessionStorage<string>('maintenance-redirect-url', null)
  if (redirectUrl.value) {
    return false
  }

  redirectUrl.value = to.fullPath

  router.push(createErrorLocation(to, StatusCodes.SERVICE_UNAVAILABLE))
  return false
}

function handleMisdirectedRequest(): boolean {
  const to = router.currentRoute.value
  /**
   * TODO: Remove this when we handle invalid tenants while routing
   *
   * Currently we have no way to identify in the frontend if a tenant exists or not. Therefore we can only
   * decide when a request to the backend fails with a 421. This is not ideal because we do not have a
   * possibility to handle other 421 errors differently with this global handling.
   */
  router.push({
    name: 'Error404',
    params: { pathMatch: to.path.substring(1).split('/') },
    query: to.query,
    hash: to.hash,
  })
  return true
}
