import { createQueryKeys } from '@lukemorales/query-key-factory'
import { toValue } from 'vue'
import { documentTemplateApi } from '@js/api/documentTemplateApi'
import type { MaybeRefOrGetter } from 'vue'
import type { Currency } from '@js/model/currency'

export const documentTemplates = createQueryKeys('documentTemplates', {
  single: (id: MaybeRefOrGetter<Currency['id']>) => ({
    queryKey: [id],
    queryFn: () => documentTemplateApi.fetchById(toValue(id)).then((response) => response.data),
  }),
  list: (params) => ({
    queryKey: [params],
    queryFn: () =>
      documentTemplateApi.fetchByQuery(toValue(params)).then((response) => response.data),
  }),
})
