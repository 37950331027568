import $ from 'jquery'

/**
 * Implements a jQuery method to make any combination of title+section collapsible
 *
 * @param options
 */
$.fn.collapsibleSection = function (options) {
  $.each(this, function () {
    const $target = $(this)

    /**
     * Define plugin default options
     */
    const settings = $.extend(
      {
        animationSpeed: 300,
        collapsedHandlerClass: 'collapsible-section-trigger-collapsed',
        trigger: $target.parent().find('.js-collapsible-section-trigger'),
        visibleHandlerClass: 'collapsible-section-trigger-visible',
      },
      options
    )

    const $trigger = $(settings.trigger)

    /**
     * Applies to the handler. The function argument determines the $target that will toggle
     * Handle the collapsible sidebar sections
     */
    $trigger.on('click.collapsibleSection', function (event) {
      event.preventDefault()
      const $self = $(this)
      $self.removeClass(settings.collapsedHandlerClass)
      $self.removeClass(settings.visibleHandlerClass)
      if ($target.is(':visible')) {
        $target.slideUp(settings.animationSpeed)
        $self.addClass(settings.collapsedHandlerClass)
      } else {
        $target.slideDown(settings.animationSpeed)
        $self.addClass(settings.visibleHandlerClass)
      }
    })

    /**
     * Apply a class to the handler depending on the section status
     */
    if ($target.is(':visible')) {
      $trigger.addClass(settings.visibleHandlerClass)
    } else {
      $trigger.addClass(settings.collapsedHandlerClass)
    }
  })
}
