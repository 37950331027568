import { createQueryKeys } from '@lukemorales/query-key-factory'
import { unref } from 'vue'
import { workflowTransitionApi } from '@js/api/workflowTransitionApi'
import type { MaybeRef } from '@vueuse/core'
import type { Unit } from '@js/model/unit'

export const workflowTransitions = createQueryKeys('workflowTransition', {
  single: (id: MaybeRef<Unit['id']>) => {
    return {
      queryKey: [id],
      queryFn: () => workflowTransitionApi.fetchById(unref(id)).then((response) => response.data),
    }
  },
})
