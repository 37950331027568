import BaseLayout from '@js/layouts/BaseLayout.vue'
import OutsideAppLayout from '@js/layouts/OutsideAppLayout.vue'
import Translator from '@js/translator'
import { useAuthStore } from '@js/stores/auth'
import type { RouteLocation, RouteRecordRaw } from 'vue-router'

export default [
  {
    component: () => import('@js/pages/security/ConfirmTwoFactor.vue'),
    meta: {
      auth: false,
      layout: OutsideAppLayout,
    },
    name: 'ConfirmTwoFactor',
    path: '/security/two-factor/confirm',
  },
  {
    path: '/logout',
    name: 'AppLogout',
    component: { name: 'Dummy' },
    meta: {
      auth: false,
      globalSearch: {
        icon: 'arrow-right',
        name: () => Translator.trans('u2_core.logout'),
      },
    },
    beforeEnter: async () => {
      const authStore = useAuthStore()
      await authStore.logout()
      return { name: 'AppLogin' }
    },
  },
  {
    component: () => import('@js/pages/security/AppLogin.vue'),
    meta: {
      auth: false,
      layout: OutsideAppLayout,
    },
    name: 'AppLogin',
    path: '/login',
    beforeEnter: async () => {
      const authStore = useAuthStore()
      await authStore.processJwtCookie()
      if (authStore.isTokenValid()) {
        return { name: 'AppDefaultDashboard' }
      }
    },
  },
  {
    component: () => import('@js/pages/user/PasswordResetRequest.vue'),
    meta: {
      auth: false,
      layout: OutsideAppLayout,
    },
    name: 'PasswordResetRequest',
    path: '/user/resetting/request',
  },
  {
    component: () => import('@js/pages/user/PasswordReset.vue'),
    meta: {
      auth: false,
      layout: OutsideAppLayout,
    },
    name: 'PasswordReset',
    path: '/user/resetting/reset/:token',
    props: (route: RouteLocation) => ({ token: route.params.token }),
  },
  {
    component: () => import('@js/pages/user/ChangePassword.vue'),
    meta: {
      auth: true,
      layout: BaseLayout,
    },
    name: 'ChangePassword',
    path: '/user/password/change',
  },
] as Array<RouteRecordRaw>
