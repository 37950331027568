import { createQueryKeys } from '@lukemorales/query-key-factory'
import { unref } from 'vue'
import { documentEditDataApi } from '@js/api/documentEditDataApi'
import type { TaskParams } from '@js/composable/useDocumentEditDataQuery'
import type { MaybeRef } from '@vueuse/core'

export const document = createQueryKeys('document', {
  editDocumentData: (taskParams: MaybeRef<TaskParams>) => {
    return {
      queryKey: [taskParams],
      queryFn: () => {
        const params = unref(taskParams)
        return documentEditDataApi
          .fetchByTask(params.shortName, params.id)
          .then((response) => response.data)
      },
      contextQueries: {
        sections: {
          queryKey: null,
          queryFn: () => {
            const params = unref(taskParams)
            return documentEditDataApi
              .fetchRenderedSections(params.shortName, params.id)
              .then((response) => response.data)
          },
        },
      },
    }
  },
})
