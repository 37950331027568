// Importing tippy.js styles before app.less so that our custom styles apply to the tooltips
import 'tippy.js/dist/tippy.css'
import 'tippy.js/dist/svg-arrow.css'
import '../css/app.css'
import '@js/behaviors/collapsible-section.jquery'
import '@js/behaviors/scroll-to.jquery'
import '@js/behaviors/tax-accounting-tooltip.jquery'
import '@js/behaviors/tooltip.jquery'
import Axios from '@js/axios/axios-setup'
import vueCreate from '@js/vue'

Axios.setup()

vueCreate()
