import { createQueryKeys } from '@lukemorales/query-key-factory'
import { unref } from 'vue'
import { userApi } from '@js/api/userApi'
import type { MaybeRef } from '@vueuse/core'
import type { User } from '@js/model/user'

export const users = createQueryKeys('users', {
  all: {
    queryKey: null,
    queryFn: () => userApi.fetchAll().then((response) => response.data),
  },
  single: (id: MaybeRef<User['id']>) => {
    return {
      queryKey: [id],
      queryFn: () => userApi.fetchById(unref(id)).then((response) => response.data),
      contextQueries: {
        userGroups: {
          queryKey: null,
          queryFn: () =>
            userApi.fetchAssignedUserGroups(unref(id)).then((response) => response.data),
        },
        roles: {
          queryKey: null,
          queryFn: () => userApi.fetchAssignedRoles(unref(id)).then((response) => response.data),
        },
        units: {
          queryKey: null,
          queryFn: () => userApi.fetchAssignedUnits(unref(id)).then((response) => response.data),
        },
        inheritedRoles: {
          queryKey: null,
          queryFn: () => userApi.fetchInheritedRoles(unref(id)).then((response) => response.data),
        },
        inheritedUnits: {
          queryKey: null,
          queryFn: () => userApi.fetchInheritedUnits(unref(id)).then((response) => response.data),
        },
        dashboards: {
          queryKey: null,
          queryFn: () =>
            userApi.fetchAssignedDashboards(unref(id)).then((response) => response.data),
        },
      },
    }
  },
})
