import axios from 'axios'
import type { ConditionType } from '@js/model/workflow'
import type { HydraCollectionResponse } from '@js/types'

export const basePath = '/api/workflow/transition/condition-types'

function fetchAll() {
  return axios.get<HydraCollectionResponse<ConditionType>>(basePath, {
    params: { pagination: false },
  })
}

function fetchByIri(iri: ConditionType['@id']) {
  return axios.get<ConditionType>(iri)
}

function fetchById(id: ConditionType['id']) {
  return fetchByIri(`${basePath}/${id}`)
}

export const transitionConditionTypeApi = {
  fetchAll,
  fetchById,
  fetchByIri,
}
