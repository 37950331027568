import findKey from 'lodash/findKey'
import Translator from '@js/translator'
import type { ApiResource, ApiResourceId, Replace } from '@js/types'
import type { CheckState } from '@js/model/checkstate'
import type { Review } from '@js/model/review'
import type { SavedFilterInformation } from '@js/model/saved-filter'

export const taskTypes = {
  ApmTransaction: 'apm_transaction',
  Contract: 'cm_contract',
  CountryByCountryReport: 'tpm_country_by_country_report',
  FinancialData: 'tpm_financial_data',
  Igt1Transaction: 'igt_igt1_transaction',
  Igt2Transaction: 'igt_igt2_transaction',
  Igt3Transaction: 'igt_igt3_transaction',
  Igt4Transaction: 'igt_igt4_transaction',
  Igt5Transaction: 'igt_igt5_transaction',
  IncomeTaxPlanning: 'tam_income_tax_planning',
  LocalFile: 'tpm_local_file',
  LossCarryForward: 'tam_loss_carry_forward',
  MainBusinessActivity: 'tpm_main_business_activity',
  MasterFile: 'tpm_master_file',
  OtherDeadline: 'tcm_other_deadline',
  TaxAssessmentMonitor: 'tcm_tax_assessment_monitor',
  TaxAssessmentStatus: 'tam_tax_assessment_status',
  TaxAuditRisk: 'tam_tax_audit_risk',
  TaxAuthorityAuditObjection: 'tcm_tax_authority_audit_objection',
  TaxConsultingFee: 'tam_tax_consulting_fee',
  TaxCredit: 'tam_tax_credit',
  TaxFilingMonitor: 'tcm_tax_filing_monitor',
  TaxLitigation: 'tam_tax_litigation',
  TaxRate: 'tam_tax_rate',
  TaxRelevantRestriction: 'tam_tax_relevant_restriction',
  Transaction: 'tpm_transaction',
  TransferPricing: 'tam_transfer_pricing',
  UnitPeriod: 'unit_period',
} as const

type TaskTypesKeys = keyof typeof taskTypes
export type TaskTypes = (typeof taskTypes)[TaskTypesKeys]
export type TaskTypeKey = TaskTypesKeys
export type TaskType = (typeof taskTypes)[keyof typeof taskTypes]
export type TaskShortName = Replace<TaskTypes, '_', '-'>

export const taskTypeToReadableName: Record<string, () => string> = {
  [taskTypes.ApmTransaction]: () => Translator.trans('APM Transaction'),
  [taskTypes.Contract]: () => Translator.trans('u2_contractmanagement.contract'),
  [taskTypes.CountryByCountryReport]: () => Translator.trans('u2_tpm.country_by_country_report'),
  [taskTypes.FinancialData]: () => Translator.trans('u2_tpm.financial_data'),
  [taskTypes.Igt1Transaction]: () => 'IGT 1 - Equity-type transactions, debt and asset transfer',
  [taskTypes.Igt2Transaction]: () => 'IGT 2 - Derivatives',
  [taskTypes.Igt3Transaction]: () => 'IGT 3 - Off-balance sheet and contingent liabilities',
  [taskTypes.Igt4Transaction]: () => 'IGT 4 - Insurance & Reinsurance',
  [taskTypes.Igt5Transaction]: () => 'IGT 5 - Profit & Loss Transactions',
  [taskTypes.IncomeTaxPlanning]: () => Translator.trans('u2_tam.income_tax_planning'),
  [taskTypes.LocalFile]: () => Translator.trans('u2_tpm.local_file'),
  [taskTypes.LossCarryForward]: () => Translator.trans('u2_tam.loss_carry_forward'),
  [taskTypes.MainBusinessActivity]: () => Translator.trans('u2_tpm.main_business_activity'),
  [taskTypes.MasterFile]: () => Translator.trans('u2_tpm.master_file'),
  [taskTypes.OtherDeadline]: () => Translator.trans('u2_tcm.other_deadline'),
  [taskTypes.TaxAssessmentMonitor]: () => Translator.trans('u2_tcm.tax_assessment_monitor'),
  [taskTypes.TaxAssessmentStatus]: () => Translator.trans('u2_tam.tax_assessment_status'),
  [taskTypes.TaxAuditRisk]: () => Translator.trans('u2_tam.tax_audit_risk'),
  [taskTypes.TaxAuthorityAuditObjection]: () =>
    Translator.trans('u2_tcm.tax_authority_audit_objection'),
  [taskTypes.TaxConsultingFee]: () => Translator.trans('u2_tam.tax_consulting_fee'),
  [taskTypes.TaxCredit]: () => Translator.trans('u2_tam.tax_credit'),
  [taskTypes.TaxFilingMonitor]: () => Translator.trans('u2_tcm.tax_filing_monitor'),
  [taskTypes.TaxLitigation]: () => Translator.trans('u2_tam.tax_litigation'),
  [taskTypes.TaxRate]: () => Translator.trans('u2_tam.tax_rate'),
  [taskTypes.TaxRelevantRestriction]: () => Translator.trans('u2_tam.tax_relevant_restriction'),
  [taskTypes.Transaction]: () => Translator.trans('u2_tpm.transaction'),
  [taskTypes.TransferPricing]: () => Translator.trans('u2_tam.transfer_pricing'),
  [taskTypes.UnitPeriod]: () => Translator.trans('u2.datasheets.status_monitor'),
} as const

export const taskTypeUrlPatternToShortNameMap: Record<string, TaskShortName> = {
  '/apm/transaction': 'apm-transaction',
  '/contract-management/contract': 'cm-contract',
  '/igt/transaction/igt1': 'igt-igt1-transaction',
  '/igt/transaction/igt2': 'igt-igt2-transaction',
  '/igt/transaction/igt3': 'igt-igt3-transaction',
  '/igt/transaction/igt4': 'igt-igt4-transaction',
  '/igt/transaction/igt5': 'igt-igt5-transaction',
  '/tam/income-tax-planning': 'tam-income-tax-planning',
  '/tam/loss-carry-forward': 'tam-loss-carry-forward',
  '/tam/tax-assessment-status': 'tam-tax-assessment-status',
  '/tam/tax-audit-risk': 'tam-tax-audit-risk',
  '/tam/tax-consulting-fee': 'tam-tax-consulting-fee',
  '/tam/tax-credit': 'tam-tax-credit',
  '/tam/tax-litigation': 'tam-tax-litigation',
  '/tam/tax-rate': 'tam-tax-rate',
  '/tam/tax-relevant-restriction': 'tam-tax-relevant-restriction',
  '/tam/transfer-pricing': 'tam-transfer-pricing',
  '/tcm/other-deadline': 'tcm-other-deadline',
  '/tcm/tax-assessment-monitor': 'tcm-tax-assessment-monitor',
  '/tcm/tax-authority-audit-objection': 'tcm-tax-authority-audit-objection',
  '/tcm/tax-filing-monitor': 'tcm-tax-filing-monitor',
  '/tpm/country-by-country-report': 'tpm-country-by-country-report',
  '/tpm/financial-data': 'tpm-financial-data',
  '/tpm/local-file': 'tpm-local-file',
  '/tpm/main-business-activity': 'tpm-main-business-activity',
  '/tpm/master-file': 'tpm-master-file',
  '/tpm/transaction': 'tpm-transaction',
  '/tax-accounting/unit-period': 'unit-period',
}

export type TaskListInfo = ApiResource & {
  '@type'?: TaskType
  canCreate: boolean
  canDelete: boolean
  canWrite: boolean
  hasDocument: boolean
  hasMultipleOptionsForNew: boolean
  itemPluralName: string
  newPath: string
  savedFilterInformation: SavedFilterInformation
  shortName: TaskShortName
  tableHasUql: boolean
  optionsForNew: object
}

export type TaskExtra = {
  addReviewPath: string
  canAddReview: boolean
  canAttach: boolean
  canDelete: boolean
  canViewConfiguration: boolean
  canEditConfiguration: boolean
  canViewDocumentPermissions: boolean
  canRemoveReview: boolean
  canViewDocument: boolean
  canWrite: boolean
  createdAt: string
  createdBy?: string
  deletePath: string
  displayName: string
  documentBaseTemplate?: string
  duplicatePath?: string
  editDocumentPath?: string
  editFormPath: string
  editPath?: string
  hasDocument: boolean
  hasMultipleOptionsForNew: boolean
  hasUserReviewed: boolean
  isImported: boolean
  isUserWatching: boolean
  listPath: string
  layoutsPath?: string
  name?: string
  newPath: string
  pdfPath?: string
  readableTaskType: string
  removeReviewPath?: string
  reporterId?: number
  shortName: TaskShortName
  sourceId?: number
  sourcePath?: string
  submitEditFormPath: string
  taskTypeId: number
  transactionType?: string
  unwatchPath: string
  updatedAt: string
  updatedBy: string | null
  watchPath: string
  xmlPath?: string
}

export type Task = ApiResource & {
  id: string
  checkStates: Array<CheckState>
  status: ApiResourceId
  unit: ApiResourceId
  period: ApiResourceId
  watchers: Array<ApiResourceId>
  assignee: ApiResourceId | undefined
  taskType: TaskType
  layoutCollectionCount: number
  reviews: Array<Review>
  'u2:extra': TaskExtra
}

export function isTask(entity: ApiResource): entity is Task {
  return entity['@type'] === 'Task'
}

export const getTaskType = (shortName: TaskShortName) => {
  const taskType = findKey(taskTypes, (value) => value === shortNameToTaskType(shortName))
  if (taskType === undefined) {
    throw new Error(`Unknown task short name: ${shortName}`)
  }

  return taskType
}

export const getReadableTaskTypeByShortName = (shortName: TaskShortName) => {
  const translateTaskTypeCallback = taskTypeToReadableName[shortNameToTaskType(shortName)]
  if (translateTaskTypeCallback !== undefined) {
    return translateTaskTypeCallback()
  }

  throw new Error(`Unknown short name: ${shortName}`)
}

export function shortNameToTaskType(shortName: TaskShortName): TaskType {
  return shortName.replace(/-/g, '_') as TaskType
}

export function taskTypeToShortName(taskType: TaskType): TaskShortName {
  return taskType.replace(/_/g, '-') as TaskShortName
}

export function taskTypeToTaskTypeKey(taskType: TaskType): TaskTypeKey {
  const taskTypeKey = findKey(taskTypes, (value) => value === taskType)
  if (taskTypeKey === undefined) {
    throw new Error(`Unable to find task resource name for task type: ${taskType}`)
  }
  return taskTypeKey as TaskTypeKey
}
